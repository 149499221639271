import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format, subYears } from "date-fns";
import { Spacer, Text, EloTable } from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 50%;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  border: 2px solid ${theme.colors.grey20};
  font-size: ${({ fontSize }) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeight.semibold};
  color: ${theme.colors.primary100};
  padding: ${({ padding }) => (padding ? padding : theme.spacing.x2)};
  outline: none;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EloSearch = (props) => {
  const [horseName, setHorseName] = useState("");
  const [riderName, setRiderName] = useState("");

  const twoYearsAgo = subYears(Date.now(), 2);

  let horseFilter = "";
  let athleteFilter = "";
  if (horseName) {
    horseFilter = `&horses_filter[name_contains]=${horseName}`;
  }
  if (riderName) {
    athleteFilter = `&athletes_filter[display_name_contains]=${riderName}`;
  }

  let url = `provider/${envVars.providerId}/elo`
    + `?return_top=25`
    + `&date_from=${format(twoYearsAgo, "yyyy-MM-dd")}`
    + `${horseFilter}`
    + `${athleteFilter}`

  const { getAll: getAllElos, all: elos } = useResource(url);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllElos();
  }, [getAllElos, horseName, riderName]);

  return (
    <>
      <HeaderContainer>
        <Text size="x6" weight="semibold">
          Search for a specific Rider/Horse
        </Text>
      </HeaderContainer>
      <Spacer height="x4" />
      <OutterWrapper>
        <div style={{ width: "98%" }}>
          <Text size="x4" weight="semibold">
            Search for a Rider
          </Text>
          <Input
            placeholder="Enter name"
            onChange={(e) => setRiderName(e.target.value)}
          />
        </div>
        <Spacer width="x2" />
        <div style={{ width: "98%" }}>
          <Text size="x4" weight="semibold">
            Search for a Horse
          </Text>
          <Input
            placeholder="Enter name"
            onChange={(e) => setHorseName(e.target.value)}
          />
        </div>
      </OutterWrapper>
      <Spacer height="x4" />
      {elos ? (
        <EloTable title={"ELO Rankings"} list={elos.data} />
      ) : (
        <Text size="x5" color="grey30" weight="semibold" align="center">
          Please enter a name for a Rider/Horse
        </Text>
      )}
      <Spacer height="x8" />
    </>
  );
};

export default EloSearch;
