import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HStack, Spacer, Text, VStack } from "../components";
import { Chevron } from "../assets";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
    isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;
const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;
const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const BannerImage = styled.img`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const Image = styled.img`
  height: 50px;
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 70px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-items: center;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 29%;
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const ListItem = ({ item, idx }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.pointsRank}.`}</Text>
        </Column>
        {/* <Column width={"15%"}>
          <img
            alt="Flag"
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.nf}.png`}
          />
        </Column> */}
        <Column width={"57.5%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.athlete}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.horse}
          </Text>
        </Column>
        <Column width={"37.5%"}>
          <Text align="right" size="x4" weight="bold" color="grey100">
            {item.attributes.totalPoints}
          </Text>
          <Text size="x4" weight="light" align="right" color="grey100">
            from {item.attributes.outings.length} outings
          </Text>
        </Column>
        <Column width={"5%"} onClick={() => setAccordionOpen(!accordionOpen)}>
          <Chevron
            direction={accordionOpen ? "top" : "bottom"}
            fill={theme.colors.secondary100}
          />
        </Column>
      </HStack>
      <Spacer height="x2" />
      {accordionOpen && (
        <>
          <HStack align="center" justify="space-between">
            <Text align="right" size="x4" weight="bold" color="grey100">
              Date
            </Text>
            <Spacer width="x4" />
            <>
              <Text align="right" size="x4" weight="bold" color="grey100">
                Show
              </Text>
            </>
            <Spacer width="x4" />
            <Text align="right" size="x4" weight="bold" color="grey100">
              Points Earned
            </Text>
          </HStack>
          {item.attributes.outings.map((item) => {
            return (
              <HStack align="center" justify="space-between">
                <Text align="right" size="x4" weight="bold" color="grey100">
                  {item.date}
                </Text>
                <Spacer width="x4" />
                <Text align="right" size="x4" weight="bold" color="grey70">
                  {item.show}
                </Text>
                <Spacer width="x4" />
                <Text align="right" size="x4" weight="bold" color="grey100">
                  {item.pointsEarned}
                </Text>
              </HStack>
            );
          })}
        </>
      )}
    </ListItemContainer>
  );
};

const EILeague = (
  {
    leagueClass,
    erLevel,
    under,
    title,
    amateur,
    scalePoints,
    rerankScores,
    over,
    horseNameContains,
    sponsor,
    bannerImage,
    adverts,
    img,
    link,
    dateTo,
    returnTop,
  },
  ...props
) => {
  const [paIndex, setPaIndex] = useState(10);

  let nameContainsParam = "";
  if (horseNameContains)
    nameContainsParam = `&horses_filter[name_contains]=${horseNameContains}`;

  let leagueClassParam = "";
  if (leagueClass) leagueClassParam = `&class=${leagueClass}`;

  let overParam = "";
  if (over) overParam = `&athlete_age_over=${over}`;

  let underParam = "";
  if (under) underParam = `&athlete_age_up_to=${under}`;

  let erLevelParam = "";
  if (erLevel) erLevelParam = `&er_levels[]=${erLevel}`;

  let amateurParam = "";
  if (amateur) {
    amateurParam = `&amateur_flag=true`;
  } else if (over === 18 && erLevel === 2) {
    amateurParam = `&amateur_flag=false`;
  }

  let treoEileParam = "";
  if (title === "Treo Eile") {
    treoEileParam = "&class_types[]=CNC";
  }

  let scalePointsParam = "";
  if (scalePoints) {
    scalePointsParam = `&scale_points=true`;
  }

  let rerankScoresParam = "";
  if (rerankScores) {
    rerankScoresParam = `&rerank_scores=true`;
  }


  let url = `irl_points_league`
    + `?date_from=${envVars.pointsLeagueDateFrom}`
    + `&date_to=${dateTo || envVars.pointsLeagueDateTo}`
    + `${leagueClassParam}`
    + `${underParam}`
    + `&return_top=${returnTop || "20"}`
    + `${erLevelParam}`
    + `${amateurParam}`
    + `${scalePointsParam}`
    + `${rerankScoresParam}`
    + `${overParam}`
    + `${treoEileParam}`
    + `${nameContainsParam}`

  const { getAll: getAllData, all: data } = useResource(url);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return data ? (
    <>
      <HStack align="center" justify="space-between">
        <VStack>
          <Text size="x6" weight="semibold">
            {title} Points League
          </Text>
          {sponsor && (
            <>
              <Spacer height="x1" />
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Text size="x4" weight="normal" color="secondary70">
                  Sponsored by {sponsor}
                </Text>
              </a>
            </>
          )}
        </VStack>
        {sponsor && img && (
          <ImageContainer>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Image alt={sponsor} src={require(`../assets/${img}`)} />
            </a>
          </ImageContainer>
        )}
      </HStack>
      <Spacer height="x6" />
      {sponsor && bannerImage && bannerImage.img &&
        <>
          <a href={bannerImage.link}><BannerImage alt={bannerImage.alt} src={require(`../assets/${bannerImage.img}`)} width="100%" /></a>
          <Spacer height="x6" />
        </>
      }
      <Wrapper>
        <OutterWrapper>
          <Container>
            <TableContainer>
              <ListItemContainer isHeader={true}>
                <Spacer height="x2" />
                <HStack align="center" justify="space-between">
                  <Header width="5%"> </Header>
                  <Header width="57.5%">Rider/Horse</Header>
                  <Header width="37.5%" align="right">
                    Total Points
                  </Header>
                </HStack>
                <Spacer height="x2" />
              </ListItemContainer>
              {data.data.slice(0, paIndex).map((item, idx) => {
                return <ListItem key={idx} item={item} idx={idx} />;
              })}
              {paIndex < data.data.length && (
                <div
                  style={{
                    padding: theme.spacing.x2,
                    background: theme.colors.grey15,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setPaIndex(data.data.length)}
                >
                  <Text
                    align="right"
                    size="x5"
                    weight="medium"
                    color="secondary100"
                  >
                    See More
                  </Text>
                </div>
              )}
            </TableContainer>
          </Container>
        </OutterWrapper>
        <Spacer height="x4" />
        {sponsor && adverts && adverts.length > 0 && (
          <AdvertContainer>
            {
              adverts.map(
                ({ img, link, alt }, idx) => {
                  return img && (
                    <>
                      <a href={link} idx={idx}>
                        <Advert alt={alt} src={require(`../assets/${img}`)} />
                      </a>
                      <Spacer height="x2" />
                    </>
                  );
                }
              )
            }
          </AdvertContainer>
        )}
      </Wrapper>
    </>
  ) : null;
};

export default EILeague;
