import React from "react";
import styled from "styled-components";
import Downshift from "downshift";
import Text from "./Text";
import Spacer from "./Spacer";
import { theme } from "../config";
import { Chevron } from "../assets";

const Input = styled.input`
  box-sizing: border-box;
  border: 2px solid ${theme.colors.grey20};
  font-size: ${({ fontSize }) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeight.semibold};
  color: ${theme.colors.secondary100};
  padding: ${({ padding }) => (padding ? padding : theme.spacing.x2)};
  outline: none;
  width: 100%;
`;

const Relative = styled.div`
  position: relative;
  & > svg,
  & > button {
    position: absolute;
    right: ${theme.spacing.x8};
    top: 40%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    margin: 0;
    & > svg {
      position: absolute;
      top: 1px;
      transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : ``)};
      @media (${theme.breakpoints.mediumAndUp}) {
        margin-right: ${theme.spacing.x4};
      }
    }
  }
`;

const Suggestions = styled.div`
  background: transparent;
  width: 99%;
  height: 300px;
  margin-top: 0.8%;
  border: 2px solid ${theme.colors.grey20};
  z-index: 1000;
  overflow: scroll;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
`;

const Wrapper = styled.div`
  margin-top: ${theme.spacing.x2};
  margin-right: 0;
  display: flex;
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-right: ${theme.spacing.x2};
    margin-top: 0;
  }
`;

const Suggestion = styled.div`
  background: ${({ highlighted }) =>
    highlighted ? theme.colors.grey10 : theme.colors.white100};
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  border-left: 3px solid
    ${({ highlighted }) =>
      highlighted ? theme.colors.primary100 : theme.colors.white100};
`;

const Dropdown = ({
  onChange,
  items,
  itemToString,
  placeholder,
  handleFilter,
  displaySuggestion,
  initialItem,
  readOnly,
  fontSize,
  padding,
  title,
  filterKey,
}) => (
  <Downshift
    onChange={onChange}
    itemToString={(item) => (item ? itemToString(item) : "")}
    initialSelectedItem={initialItem}
  >
    {({
      getRootProps,
      getInputProps,
      getItemProps,
      isOpen,
      setState,
      highlightedIndex,
      toggleMenu,
      inputValue,
      clearSelection,
      selectedItem,
      initialSelectedItem,
    }) => (
      <div style={{ width: "100%" }}>
        {title && (
          <>
            <Spacer height="x2" />
            <Text size="x4" weight="semibold">
              {title}
            </Text>
            <Spacer height="x1" />
          </>
        )}
        <Relative isOpen={isOpen}>
          <Wrapper>
            <Input
              type="text"
              fontSize={fontSize}
              placeholder={placeholder}
              padding={padding}
              onFocus={() => setState({ inputValue: "" })}
              onClick={(e) => {
                toggleMenu();
              }}
              readOnly={readOnly}
              {...getInputProps()}
            />
            {isOpen && (
              <Suggestions>
                {!readOnly && items && inputValue !== "All Nations"
                  ? items
                      .filter((i) =>
                        i.attributes[filterKey]
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                      .map((item, idx) => (
                        <Suggestion
                          {...getItemProps({
                            item,
                            key: idx,
                            highlighted: highlightedIndex === idx,
                            selected: selectedItem === item,
                          })}
                        >
                          {displaySuggestion(item)}
                        </Suggestion>
                      ))
                  : items.map((item, idx) => (
                      <Suggestion
                        {...getItemProps({
                          item,
                          key: idx,
                          highlighted: highlightedIndex === idx,
                          selected: selectedItem === item,
                        })}
                      >
                        {displaySuggestion(item)}
                      </Suggestion>
                    ))}
              </Suggestions>
            )}
          </Wrapper>
          <button onClick={toggleMenu}>
            <Chevron isOpen={isOpen} />
          </button>
        </Relative>
      </div>
    )}
  </Downshift>
);

export default Dropdown;
