import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { camelize } from "humps";
import {
  Dropdown,
  ExpandedStatList,
  Filter,
  HStack,
  Spacer,
  Text,
} from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";
import { helpers } from "../services";

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
    align-items: top;
  }
`;

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const SingleMetric = (props) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [groupBy, setGroupBy] = useState("athlete");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedLevels, setSelectedLevel] = useState(
    props.location.state.selectedLevels || envVars.allClassLevels
  );

  const [selectedYears, setSelectedYear] = useState(
    props.location.state.disableYearDD
      ? []
      : (props.location.state.selectedYears || [envVars.currentYear])
  );

  const removeClass = (index) => {
    const newArr = [...selectedLevels];
    newArr.splice(index, 1);
    setSelectedLevel(newArr);
  };

  let levelParams = helpers.buildLevelParams(selectedLevels);
  let yearParams = helpers.buildYearParams(selectedYears);

  let url = `provider/${envVars.providerId}/records`
    + `?metric_type=${props.location.state.metric.metric.toLowerCase()}`
    + `&group_by=${groupBy}`
    + `&venue_nf=irl`
    + `&return_top=100`
    + `${levelParams}`
    + `${yearParams}`

  const { getAll: getAllRecords, all: records } = useResource(url);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getAllRecords();
      setIsLoading(false);
    };

    window.scrollTo(0, 0);
    fetchData();
  }, [selectedLevels, selectedYears, groupBy, activeFilter, getAllRecords]);

  return (
    <>
      <Text size="x6" weight="semibold">
        {props.location.state.metric.title}
      </Text>
      <Spacer height="x4" />
      <OutterWrapper>
        <DropdownContainer>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Dropdown
              onChange={(item) => {
                if (item.title === "All Levels") {
                  setSelectedLevel(item.level);
                } else {
                  if (helpers.allLevelsSelected(selectedLevels)) {
                    setSelectedLevel([item]);
                  } else {
                    setSelectedLevel(
                      helpers.addSelectedLevelIfNew(selectedLevels, item)
                    );
                  }
                }
              }}
              readOnly={true}
              items={envVars.allClasses}
              itemToString={(item) => "Select a Level"}
              initialItem={selectedLevels[0]}
              placeholder="Select a Level"
              handleFilter={() => true}
              displaySuggestion={(item) => (
                <HStack align="center">
                  <input
                    type="checkbox"
                    style={{ backgroundColor: theme.colors.primary100 }}
                    checked={helpers.levelItemIsChecked(selectedLevels, item)}
                  />
                  <Text size="x3">{item.title}</Text>
                </HStack>
              )}
            />
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
              {helpers.allLevelsSelected(selectedLevels) ? (
                <div
                  style={{
                    marginTop: theme.spacing.x1,
                    marginLeft: theme.spacing.x1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text size="x4">All Levels</Text>
                  <Spacer width="x2" />
                  <svg
                    onClick={() => setSelectedLevel(envVars.majorClasses)}
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                  </svg>
                  <Spacer width="x4" />
                </div>
              ) : (
                selectedLevels.map((level, idx) => {
                  if (selectedLevels.length > 1) {
                    return (
                      <div
                        style={{
                          marginTop: theme.spacing.x1,
                          marginLeft: theme.spacing.x1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text size="x4">{level.title}</Text>
                        <Spacer width="x2" />
                        <svg
                          onClick={() => removeClass(idx)}
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 24 24"
                        >
                          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z"></path>
                        </svg>
                        <Spacer width="x4" />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          marginLeft: theme.spacing.x1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text size="x4">Selected levels:</Text>
                        <Spacer width="x3" />
                        <Text size="x4">{level.title}</Text>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
          {props.location.state.disableYearDD ? "" :
            <Dropdown
              onChange={(item) => setSelectedYear([item])}
              readOnly={true}
              items={envVars.years}
              itemToString={(item) => item}
              initialItem={selectedYears[0]}
              placeholder="Select a Year"
              handleFilter={() => true}
              displaySuggestion={(item) => <Text size="x3">{item}</Text>}
            />}
        </DropdownContainer>
        <FilterContainer>
          <div
            onClick={() => {
              setActiveFilter(0);
              setGroupBy("athlete");
            }}
          >
            <Filter active={activeFilter === 0} text="Rider" />
          </div>
          <div
            onClick={() => {
              setActiveFilter(1);
              setGroupBy("horse");
            }}
          >
            <Filter active={activeFilter === 1} text="Horse" />
          </div>
        </FilterContainer>
      </OutterWrapper>
      <Spacer height="x4" />
      <Spacer height="x8" />
      {isLoading ? (
        <Text size="x5" weight="semibold">
          Loading...
        </Text>
      ) : (
        records &&
          records.data.attributes[camelize(props.location.state.metric.metric)]
            .length > 0 ? (
          <ExpandedStatList
            title={props.location.state.metric.title}
            list={
              records.data.attributes[
              camelize(props.location.state.metric.metric)
              ]
            }
          />
        ) : (
          <Text size="x5" color="grey30" weight="semibold" align="center">
            There are no records matching your search.
          </Text>
        )
      )}
    </>
  );
};

export default SingleMetric;
