import styled from "styled-components";

import { theme } from "../config";

const getMobileTextSize = (size) => {
  if (size === "x7") {
    return "x6";
  } else if (size === "x6") {
    return "x5";
  } else if (size === "x5") {
    return "x4";
  } else if (size === "x4") {
    return "x3";
  } else {
    return "x2";
  }
};

const Text = styled.h1.attrs(props => ({
  "aria-label": props.children
}))`
  font-size: ${({ size }) => theme.fontScale[getMobileTextSize(size)]};
  font-weight: ${({ weight }) => theme.fontWeight[weight]};
  color: ${({ color }) => theme.colors[color]};
  text-align: ${({ align }) => align};
  margin-top: 0;
  margin-bottom: 0;
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${({ size }) => theme.fontScale[size]};
  }
}
`;

Text.defaultProps = {
  size: "x3",
  weight: "normal",
  color: "secondary100",
  align: "left",
};

export default Text;
