import React from "react";
import styled from "styled-components";
import { HStack, Spacer, Text } from "../components";
import { theme } from "../config";

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const RecordBoxContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 2px solid ${theme.colors.grey40};
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 50%;
  }
`;

const RecordTitleContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x4};
  background: ${theme.colors.secondary100};
`;

const RecordItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: ${({ showBorder }) =>
    showBorder ? `2px solid ${theme.colors.grey40}` : "none"};
`;

const RecordName = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
  width: 50%;
  border-right: ${({ showBorder }) =>
    showBorder ? ` 2px solid ${theme.colors.grey40}` : "none"};
`;

const RecordItem = ({ name, record, showBorder }) => {
  return (
    <RecordItemContainer showBorder={showBorder}>
      <RecordName showBorder>
        <Text size="x3" weight="semibold">
          {name}
        </Text>
      </RecordName>
      <RecordName>
        <Text size="x3">{record}</Text>
      </RecordName>
    </RecordItemContainer>
  );
};

const RecordBox = ({ title, items }) => {
  return (
    <RecordBoxContainer>
      <RecordTitleContainer>
        <Text color="white100" weight="semibold" size="x4">
          {title}
        </Text>
      </RecordTitleContainer>
      {items.map((item, idx) => {
        return (
          <RecordItem
            name={item.name}
            record={item.record}
            showBorder={idx !== items.length - 1}
          />
        );
      })}
    </RecordBoxContainer>
  );
};

const Records = () => {
  const records = [
    {
      title: "Dressage Records (all levels)",
      items: [
        {
          name: "Lowest Average DR Score in a season (Riders is 5 or more runs)",
          record: "Tori Dixon - 2021 - 23.7",
        },
        {
          name: "Most sub-28 DR Scores in a season",
          record: "Steven Smith - 2021 - 48",
        },
      ],
    },
    {
      title: "Cross Country Records",
      items: [
        {
          name: "Most XCJ Clear Rounds in a season (all levels)",
          record: "Cathal Daniels (2015) & Patrick Whelan (2022) - 102",
        },
        {
          name: "Most XCT Clear Rounds in a season (all levels)",
          record: "Steven Smith - 2021 - 77",
        },
        {
          name: "Most XCJ Clear Rounds in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 64",
        },
        {
          name: "Most XCT Clear Rounds in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 31",
        },
        {
          name: "Highest number of XC runs in a season and still 100% XCJ clear (all levels)",
          record: "Daniel Alderson - 2020 - 54",
        },
        {
          name: "Highest number of XC runs in a season and still 100% XCJ clear (EI115 and above)",
          record: "Cathal Daniels - 2020 - 31",
        },
        {
          name: "Longest XCJ Clear Streak in a season (all levels)",
          record: "Ian Cassells - 2011-2017 - 87",
        },
      ],
    },
    {
      title: "Showjumping Records",
      items: [
        {
          name: "Most SJ Clear Rounds in a season (all levels)",
          record: "Steven Smith - 2021 - 67",
        },
        {
          name: "Most SJ Clear Rounds in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 41",
        },
        {
          name: "Highest number of SJ runs in a season and still 100% clear (EI115 and above)",
          record: "Sam Watson - 2022 - 10",
        },
      ],
    },
    {
      title: "Overall Records",
      items: [
        {
          name: "Most Wins in a season (all levels)",
          record: "Steven Smith - 2021 - 33",
        },
        {
          name: "Most Wins in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 13",
        },
        {
          name: "Most podiums in a season (all levels)",
          record: "Steven Smith - 2021 - 58",
        },
        {
          name: "Most podiums in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 41",
        },
        {
          name: "Most sub-28 finishings in a season (all levels)",
          record: "Steven Smith - 2021 - 41",
        },
        {
          name: "Most Sub-28 Finishes in a season (EI115 and above)",
          record: "Ian Cassells - 2022 - 8",
        },
      ],
    },
  ];
  return (
    <>
      <HStack align="center">
        <TitleContainer>
          <Text size="x6">Records</Text>
        </TitleContainer>
      </HStack>
      <Spacer height="x4" />
      {records.map((item, idx) => {
        return <RecordBox title={item.title} items={item.items} />;
      })}
    </>
  );
};

export default Records;
