import React from "react";
import styled from "styled-components";
import { theme } from "../config";
import Text from "./Text";
import Spacer from "./Spacer";

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: ${({ active }) =>
    active
      ? `40px solid ${theme.colors.secondary100}`
      : `40px solid ${theme.colors.grey20}`};
  border-left: 40px solid transparent;
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: auto;
  height: 40px;
  background: ${({ active }) =>
    active ? theme.colors.secondary100 : theme.colors.grey20};
  padding-right: ${theme.spacing.x4};
  padding-left: ${theme.spacing.x2};
  cursor: pointer;
`;

const Filter = ({ text, active }) => {
  return (
    <div style={{ display: "flex" }}>
      <Triangle active={active} />
      <TextContainer active={active}>
        <Spacer height="x2" />
        <Text
          color={active ? "white100" : "secondary100"}
          weight="semibold"
          size="x4"
        >
          {text}
        </Text>
      </TextContainer>
    </div>
  );
};

export default Filter;
