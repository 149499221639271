const leagues = [
  {
    title: "Kentucky Horsewear Pony Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "Kentucky Horsewear EI90 (P)",
        route: "/ei90p",
        sponsor: "Kentucky Horsewear",
      },
      {
        title: "Kentucky Horsewear EI100 (P)",
        route: "/ei100p",
        sponsor: "Kentucky Horsewear",
      },
      {
        title: "Kentucky Horsewear EI110 (P)",
        route: "/ei110p",
        sponsor: "Kentucky Horsewear"
      },
    ],
  },
  {
    title: "Kentucky Horsewear Junior Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "Kentucky Horsewear EI100 (J)",
        route: "/ei100j",
        sponsor: "Kentucky Horsewear",
      },
      {
        title: "Kentucky Horsewear EI110 (J)",
        route: "/ei110j",
        sponsor: "Kentucky Horsewear"
      },
    ],
  },
  {
    title: "Kentucky Horsewear Young Rider Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "Kentucky Horsewear EI110 Young Rider",
        route: "/ei_young_rider_110",
        sponsor: "Kentucky Horsewear",
      },
      {
        title: "Kentucky Horsewear EI115 Young Rider",
        route: "/ei_young_rider_115",
        sponsor: "Kentucky Horsewear",
      },
    ],
  },
  {
    title: "Childéric Amateur Leagues",
    isCollapsable: true,
    subItems: [
      { title: "Childéric Amateur EI90", route: "/ei_amateur_90", sponsor: "Childéric", },
      { title: "Childéric Amateur EI100", route: "/ei_amateur_100", sponsor: "Childéric", },
      { title: "Childéric Amateur EI110", route: "/ei_amateur_110", sponsor: "Childéric", },
    ],
  },
  // { title: "Elo Ratings", route: "/elo" },
];
export default leagues;
